<script setup lang="ts">
</script>

<template>
  <v-footer class="pa-4 pa-lg-10 text-gray500">
    <v-row align="center" justify="space-between">
      <v-col class="text-md" cols="auto"><i-logo-gray height="1em"/></v-col>
      <v-col class="text-sm" cols="auto">© 2024 OneCrowd</v-col>
    </v-row>
  </v-footer>
</template>
